import { CHAIN_ID } from "@src/constants";
import dayjs from "dayjs";
import Countdown from "react-countdown";

interface IProps {
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
}

const PayoutCountdown: React.FunctionComponent = () => {
  const renderer = ({ hours, minutes, seconds, completed }: IProps) => {
    if (completed) {
      return <span>00:00:00</span>;
    } else {
      return <span>{hours.toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</span>;
    }
  };

  const utcNow = dayjs.utc();
  const startOfDay = utcNow.startOf('day');
  const array = [
    startOfDay.add(4, 'hours'), 
    startOfDay.add(8, 'hours'), 
    startOfDay.add(12, 'hours'),
    startOfDay.add(16, 'hours'), 
    startOfDay.add(20, 'hours'),
    startOfDay.add(24, 'hours')
  ];

  const nextPayout = array.reduce(function(prev, current) {
    return (prev && prev.diff(utcNow) < current.diff(utcNow) && prev.diff(utcNow) > 0) ? prev : current
  });

  const nextPayoutIn = CHAIN_ID === 'T' // TODO: временно
    // ? utcNow.startOf('hour').add(1, 'hours').toDate()
    ? nextPayout.toDate()
    : utcNow.hour() >= 12
      ? utcNow.add(1, 'day').startOf('day').add(12, 'hours').toDate()
      : utcNow.startOf('day').add(12, 'hours').toDate();

  return (<Countdown date={nextPayoutIn} renderer={renderer} />);
}

export default PayoutCountdown;
